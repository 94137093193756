import React from 'react';
import './Home.css'
import CustomNavbar from './Navbar';
import i404 from './images/404-error.png'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

  const history = useNavigate();

  const goHome = () => {
    history('/');
  };

  return (
    <div>    
      <CustomNavbar />
      <div className='background-containers'>
      <img src={i404} alt="Customise Trips" style={{height: '230px', margin: '2em'}}/>
        <div style={{width: '70%', margin:'5px 0 10px'}}>  
          <h2 style={{fontWeight: '700'}}>Opps! That Links Is Broken.</h2>
          <p>Page does not exist or some other error occured. Go to our Home Page</p>
        </div>
        <Button onClick={goHome} style={{padding: '0.6em', backgroundColor:'green', color: 'white', border: '0'}}>Go Home</Button>
      </div>
    </div>
  );
};

export default NotFound;
