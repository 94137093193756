import React from "react"
import CustomNavbar from "./Navbar"
import Footer from "./Footer"
import './Career.css'

function Career () {
    return (
        <div>
            <CustomNavbar />
            <div className="background_career">
                <div className="center-text_career">
                    <h2 className="background-text_career">Career</h2>
                </div>
            </div>
            <div className="career-head-div">
                <h2 className="career-title">Careers at Metayu Pharmatech</h2>
            </div>
            <div className="career-div">
                <p style={{fontWeight: '700', fontSize: '1.7em'}}>Internship Program</p>
                <p>At Metayu Pharmatech, we are committed to nurturing the next generation of pharmaceutical and cosmeceutical innovators. We believe in providing aspiring professionals with a robust platform to apply their knowledge, gain real-world experience, and contribute to groundbreaking research and product development. Our internship program is specifically designed for graduates who are ready to take the first step in their careers within an environment that celebrates innovation, sustainability, and the ancient wisdom of Ayurveda.</p>
            </div>
            <div className="career-div">
                <p style={{fontWeight: '700', fontSize: '1.7em'}}>Internship Opportunities</p>
                <p>We are currently offering internship positions for individuals who have recently graduated with a B.Pharm., M.Pharm or equivalent degree. Our program is an exceptional opportunity to work in the fields of pharmaceutical and cosmeceutical research, development, and innovation.</p>
            </div>
            <div className="career-div">
                <p style={{fontWeight: '700', fontSize: '1.7em', paddingBottom: '0.7em'}}>Our Internship Programs</p>
                <div>
                    <ul className="job-list">
                        <li className="job-heading">Research & Development</li><p>Gain hands-on experience in our state-of-the-art labs, working on projects that aim to blend Ayurvedic principles with modern pharmaceutical science.</p>
                        <li className="job-heading">Product Development</li><p>Contribute to the formulation and testing of our next range of cosmeceutical products, from concept through to market launch.</p>
                        <li className="job-heading">Quality Control Assistance</li><p>Learn about the rigorous processes involved in ensuring the safety, efficacy, and quality of pharmaceutical and cosmeceutical products.</p>
                    </ul>
                </div>
            </div>
            <div className="career-div">
                <p style={{fontWeight: '700', fontSize: '1.7em'}}>How to Apply?</p>
                <div>
                    <p>Embark on your journey to becoming a future pharmaceutical and cosmeceutical innovator with Metayu Pharmatech. If you're a recent graduate with a B.Pharm or M.Pharm degree or equivalent, and you're ready to take your first step towards a remarkable career, here's how you can apply:</p>
                    <ul>
                        <li>Step into the portal of opportunity by sending an email to <a href="mailto:careers@themetayu.com">careers@themetayu.com</a></li>
                        <li>Don't forget to sprinkle in some enthusiasm! Write "Internship Application - [Your Name]" in the subject line.</li>
                        <li>Also you'll need to include:</li>
                        <ul>
                            <li>Updated CV/Resume</li>
                            <li>Cover Letter detailing your interest in the internship and how you believe you can contribute to our mission.</li>
                            <li>Your academic transcripts</li>
                        </ul>
                    </ul>
                    <p>Join us on this thrilling adventure, where ancient wisdom meets modern innovation, and where curiosity, creativity, and hard work are celebrated. Together, let's script the future of healthcare with Metayu Pharmatech!</p>
                </div>
            </div>
            <div className="career-div">
                <p style={{fontWeight: '700', fontSize: '1.7em'}}>Our Selection Process</p>
                <p>Our selection process is designed to identify candidates who are not only academically accomplished but also demonstrate a keen interest in pharmaceutical innovation and a commitment to improving health and wellness through natural and sustainable means.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Career