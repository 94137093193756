import React from "react"
import CustomNavbar from "./Navbar"
import Footer from "./Footer"
import './News.css'
import { Chrono } from "react-chrono";

function News () {

    const items = [{
        title: "Dec 2023",
        cardTitle: "Metayu Pharmatech",
        cardDetailedText: "Founded in December 2023, Metayu Pharmatech is a trailblazer in the pharmaceutical and cosmeceutical industries, known for its pioneering research and innovative product formulations. With an unwavering focus on precision, efficacy, and sustainability, our team is dedicated to developing next-generation solutions that meet the evolving needs of consumers. Our comprehensive expertise encompasses every step of the formulation process, from initial development to final production, ensuring that each product is crafted with the highest standards in mind. As a pioneer in the field, we continue to push the boundaries of possibility, setting new benchmarks for quality and performance."
      }, {
        title: "Mar 2024",
        cardTitle: "Metayu Pharmatech Unveils New Cosmeceutical Range",
        cardDetailedText: "Metayu Pharmatech is excited to announce the upcoming launch of our innovative range of cosmeceuticals. Merging the ancient wisdom of Ayurveda with modern science, these products promise to redefine skincare and beauty standards. Stay tuned for the official release date!",
      }, {
        title: "Mar 2024",
        cardTitle: "Metayu Pharmatech Innovative Organic Powder",
        cardDetailedText: "Our R&D team has made groundbreaking progress in developing fully organic powders, offering a safe and natural alternative to talc. This development aligns with our commitment to health and environmental sustainability.",
      }];

      const reverseChrono = items => [...items].reverse();

    return (
        <div>
            <CustomNavbar />
            <div className="background_news">
                <div className="center-text_news">
                    <h2 className="background-text_news">News & Updates</h2>
                </div>
            </div>
            <div className="news-div">
                <div style={{ width: '100%', height: 'auto' }}>
                    <Chrono 
                        items={reverseChrono(items)} 
                        theme={{
                            primary: 'green',
                            secondary: 'green',
                            cardBgColor: 'white',
                            titleColor: 'green',
                            titleColorActive: 'white',
                            cardTitleColor: 'green'
                        }} 
                        mode="VERTICAL" 
                        slideShow
                        slideItemDuration={4500}
                        slideShowType="reveal"
                        hideControls 
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default News