import React from "react"
import CustomNavbar from "./Navbar"
import Footer from "./Footer"
import './R&D.css'

function RD () {
    return (
        <div>
            <CustomNavbar />
            <div className="background_rd">
                <div className="center-text_rd">
                    <h2 className="background-text_rd">Research & Development</h2>
                </div>
            </div>
            <div className="rd-div">
                <div style={{textAlign: 'center', marginBottom: '2em'}}>
                    <h2 className="rd-title">Research and Development at Metayu Pharmatech</h2>
                </div>
                <p>Innovation is at the heart of everything we do at Metayu Pharmatech. Our Research and Development (R&D) team is dedicated to pushing the boundaries of pharmaceutical and cosmeceutical science, merging the ancient wisdom of Ayurveda with cutting-edge scientific research to create products that not only enhance beauty and health but also safeguard the environment.</p>
                <div>
                    <p style={{fontWeight: '700', fontSize: '1.7em', margin: '2em 0 1.1em'}}>Our Current Projects</p>
                    <div style={{margin: '2em 0'}}>
                        <div style={{display: 'flex', fontSize: '1.3em'}}>
                            <div>      
                                <p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>Cosmeceuticals Range</p>
                            </div>
                        </div>
                        <p>We are in the process of developing our proprietary range of cosmeceutical products. Leveraging the potent synergy between natural ingredients and scientific formulation techniques, our products are designed to offer unparalleled skin and hair care benefits, aligning with our commitment to holistic well-being.</p>
                    </div>
                    <div style={{margin: '2em 0'}}>
                        <div style={{display: 'flex', fontSize: '1.3em'}}>
                            <div>
                                <p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>Organic Powders</p>
                            </div>
                        </div>
                        <p>Recognizing the need for safer, more natural alternatives to everyday products, our team is pioneering the development of fully organic powders aimed at replacing talc. These natural formulations promise to deliver all the benefits of traditional powders without the associated health risks, ensuring safety and efficacy.</p>
                    </div>
                </div>
                <div>
                    <p style={{fontWeight: '700', fontSize: '1.7em', margin: '2em 0 0.8em'}}>Innovative Wellness</p>
                    <p>At Metayu Pharmatech, our R&D efforts are driven by a commitment to excellence, sustainability, and the well-being of our community. We believe in the power of research to transform lives and are dedicated to creating a healthier, more sustainable future through innovation. Join us on this journey of discovery and transformation, where ancient wisdom and modern science unite to pave the way for a brighter tomorrow.</p>
                </div>
            </div>
            <div>
            
            </div>
            <Footer />
        </div>
    )
}

export default RD

/*
<div style={{margin: '2em 0'}}>
                        <p style={{display: 'flex', fontSize: '1.3em'}}><p style={{borderRadius: '50%', backgroundColor: 'green', padding: '0.3em 0.8em', color: 'white', fontWeight: '800'}}>3</p><p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>&nbsp;Organic Hair Dye</p></p>
                        <p style={{marginTop: '-1em'}}>The quest for a fully organic hair dye is another exciting avenue of our research. Our goal is to create a product that not only offers vibrant, long-lasting color but also cares for the hair and scalp, free from the harsh chemicals typically found in conventional hair dyes.</p>
                    </div>
                    <div style={{margin: '2em 0'}}>
                        <p style={{display: 'flex', fontSize: '1.3em'}}><p style={{borderRadius: '50%', backgroundColor: 'green', padding: '0.3em 0.8em', color: 'white', fontWeight: '800'}}>4</p><p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>&nbsp;Oral Cancer Research</p></p>
                        <p style={{marginTop: '-1em'}}>Understanding the critical impact of oral cancer on global health, Metayu Pharmatech is embarking on a significant research project focused on this devastating disease. We plan to collaborate with advanced laboratories outside India, bringing together international expertise and resources to advance understanding and develop innovative treatment options.</p>
                    </div>
                    <div style={{margin: '2em 0'}}>
                        <p style={{display: 'flex', fontSize: '1.3em'}}><p style={{borderRadius: '50%', backgroundColor: 'green', padding: '0.3em 0.8em', color: 'white', fontWeight: '800'}}>5</p><p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>&nbsp;Carbon Substitute</p></p>
                        <p style={{marginTop: '-1em'}}>In our pursuit of more environmentally friendly and health-conscious alternatives, we are also researching substitutes for carbon black. This initiative not only aims to reduce reliance on petroleum-based products but also to introduce safer, sustainable options in various industries, including cosmetics and pharmaceuticals.</p>
                    </div>
*/