import React, {useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import logo from './images/logo.png'; // Import your logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import Form from 'react-bootstrap/Form';
/*import { database } from './firebase';
import { onValue, ref, push, get } from "firebase/database";
import emailjs from '@emailjs/browser';*/

const Footer = () => {

  const currentYear = new Date().getFullYear();

  const [formData, setFormData] = useState({
    email: '',
});

/*const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const saveDataToFirebase = (e) => {
    e.preventDefault();

    const dataToStore = {
        email: formData.email
    };

    const dataRef = ref(database, 'customise_querry');

    push(dataRef, dataToStore)
      .then(() => {
        console.log('Data has been stored in Firebase.');

        // Send email using EmailJS with the data from Firebase
        emailjs.send('service_y6etgxl', 'template_5jum5vk', {
          to_email: 'info@fliptotrip.com',
          subject: 'New Trip Customisation Request',
          firebaseData: formatEmailBody(dataToStore),
        }, 'SjRd2OL96xw44oUcE')
          .then((result) => {
            console.log('Email sent successfully:', result.text);
          })
          .catch((error) => {
            console.error('Error sending email:', error.text);
          });

        setShowModal(true);
      })
      .catch((error) => {
        console.error('Error storing data in Firebase:', error);
      });
};

  const formatEmailBody = (data) => {
    return `
    Email: ${data.email}   
    `;
  }; */

  return (
    <footer className="text-white" style={{padding: '3em 3em 1em', backgroundColor: '#FFFAE4', fontFamily: 'Maven Pro'}}>
      <Container>
        <Row>
          <Col xs={12} md={3} style={{marginRight: '1.5em'}}>
            <img src={logo} alt="Logo" className='logo-footer-company' />
            <ul className='footer-main-tagline'>
              Harmonizing Tradition with Innovation for Healthier Tomorrows.
            </ul>
            
          </Col>
          <Col xs={12} md={2} style={{marginRight: '1.2em'}}>
            <h4 style={{color:'#06717E', fontWeight: '800', paddingBottom: '0.3em'}}>Our Metayu</h4>
            <ul className="list-unstyled">
              <li><a href="/about-us" className='footer-link-content'>About Us</a></li>
              <li><a href="/career" className='footer-link-content'>Career</a></li>
              <li><a href="/news" className='footer-link-content'>Latest News</a></li>
              <li><a href="/R&D" className='footer-link-content'>R&D</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3} style={{marginRight: '1.6em'}}>
            <h4 style={{color:'#06717E', fontWeight: '800', paddingBottom: '0.3em'}}>Contact Info</h4>
            <ul className="list-unstyled">
              <li style={{color:'#06717E', width: '85%'}}>Metayu Pharmatech</li>
              <li style={{color:'#06717E', width: '90%'}}>Flat No. 6, Sukh-Shanti Apt., Anand Nagar, Gangapur Road, Nashik (MH), India - 422013.</li>
              <li style={{color:'#06717E', width: '90%', marginTop: '0.4em'}}><b>GSTIN:</b> 27AGGPW7223D1ZA</li>
              <li style={{marginTop: '0.3em'}}><a href='tel:+919740096300' className='footer-link-content'>+91 9975095367</a></li>
              <li style={{marginTop: '0.3em'}}><a href='mailto:info@themetayu.com' className='footer-link-content'>info@themetayu.com</a></li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <h4 style={{color:'#06717E', fontWeight: '800', paddingBottom: '0.3em'}}>Join Us Now</h4>
            <p style={{color: '#06717E'}}>Stay informed with our newsletter for exclusive updates and offers.</p>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div style={{display: 'flex'}}>
                  <Form.Control type="email" placeholder="Your E-Mail ID" style={{opacity: '0.8', marginRight:'0.2em'}}/>
                  <Button variant="primary" className="ml-2" style={{backgroundColor: '#FF8C00', border: '0px'}}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <hr />
      <div>
        <p className='footer-copy'>&copy; {currentYear} Metayu Pharmatech | All Rights Reserved.</p>
        <p className='footer-copy-1'>Designed and Developed by <a href="http://www.musomint.in" target='_blank' style={{color: '#06717E', textDecoration: 'none', fontWeight: '700'}}>Musomint™</a> Web Technologies</p>
        <p className='footer-copy-2'>Powered by <a href="http://www.musomint.in" target='_blank' style={{color: '#06717E', textDecoration: 'none', fontWeight: '700'}}>Musomint™</a> Broadcasting & Media</p>
      </div>
    </footer>
  );
};

export default Footer;

/*
<div className="social-media-links">
                <a href="https://www.facebook.com/fliptotriptravel/" target='_blank'>
                    <FontAwesomeIcon icon={faFacebook} className='social-link-content'/>
                </a>
                <a href="https://www.youtube.com/@fliptotrip" target='_blank'>
                    <FontAwesomeIcon icon={faYoutube} className='social-link-content'/>
                </a>
                <a href="https://www.instagram.com/flip2trip/" target='_blank'>
                    <FontAwesomeIcon icon={faInstagram} className='social-link-content'/>
                </a>
                <a href="https://www.linkedin.com/company/fliptotrip/" target='_blank'>
                    <FontAwesomeIcon icon={faLinkedin} className='social-link-content'/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=919740096300&text&type=phone_number&app_absent=0" target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} className='social-link-content'/>
                </a>
            </div>
*/