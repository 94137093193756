import React from "react"
import CustomNavbar from "./Navbar"
import Footer from "./Footer"
import './Contact.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Contact () {
    return (
        <div>
            <CustomNavbar />
            <div className="background_contact">
                <div className="center-text_contact">
                    <h2 className="background-text_contact">Contact Us</h2>
                </div>
            </div>
            <div className="contact-min-div">
                <h2 className="contact-title">Get in Touch with Metayu Pharmatech for Your Next Breakthrough in Health and Beauty</h2>
                <p style={{textAlign: 'center'}}>Explore a wealth of opportunities by getting in touch with Metayu Pharmatech. Whether you're interested in cutting-edge cosmeceutical research or a stimulating internship, our dedicated team is here to guide you. Discover how our harmonized approach to traditional wisdom and modern science can transform your career and contribute to healthier tomorrows.</p>
            </div>
            <div style={{padding: '4em', fontFamily: 'Maven Pro'}}>
                <h2 style={{textAlign: 'center', fontSize: '3em', fontWeight: '700'}}>Contact Details</h2>
                <Tabs defaultActiveKey="home" id="fill-tab-example" className="mb-3" fill style={{marginTop: '3.3em', fontFamily: 'Maven Pro'}}>
                    <Tab eventKey="home" title={<span>General Queries</span>}>
                        <div style={{marginTop: '2em'}}>
                            <a href='tel:+919740096300' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex'}}>
                                <p style={{fontWeight: '800'}}>Mob:</p><p>&nbsp;+91 9975095367</p>
                            </div></a>
                            <a href='mailto:info@themetayu.com' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex', marginTop: '-1em'}}>
                                <p style={{fontWeight: '800'}}>Email ID:</p><p>&nbsp;info@themetayu.com</p>
                            </div></a>
                        </div>
                    </Tab>
                    <Tab eventKey="profile" title={<span>Career at Metayu</span>}>
                        <div style={{marginTop: '2em'}}>
                            <a href='tel:+919740096300' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex'}}>
                                <p style={{fontWeight: '800'}}>Mob:</p><p>&nbsp;+91 9975095367</p>
                            </div></a>
                            <a href='mailto:careers@themetayu.com' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex', marginTop: '-1em'}}>
                                <p style={{fontWeight: '800'}}>Email ID:</p><p>&nbsp;careers@themetayu.com</p>
                            </div></a>
                        </div>
                    </Tab>
                    <Tab eventKey="longer-tab" title={<span>Customer Support</span>}>
                        <div style={{marginTop: '2em'}}>
                            <a href='tel:+919740096300' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex'}}>
                                <p style={{fontWeight: '800'}}>Mob:</p><p>&nbsp;+91 9975095367</p>
                            </div></a>
                            <a href='mailto:contact@themetayu.com' style={{ color: 'black', textDecoration: 'none', fontFamily: 'Maven Pro'}}><div style={{display: 'flex', marginTop: '-1em'}}>
                                <p style={{fontWeight: '800'}}>Email ID:</p><p>&nbsp;contact@themetayu.com</p>
                            </div></a>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Footer />
        </div>
    )
}



export default Contact

/*
            <div className="contact-location-div">
                <div className="contact-location-title-div"> 
                    <h2 className="contact-location-text">Visit Our Headquarters to Witness the Fusion of Tradition & Innovation</h2>
                </div>
                <div className="contact-location-add-div">
                    <p className="contact-location-add">Metayu Pharmatech Flat No.2, Mukul Apt., ST Colony, Gangapur Rd. Nashik - 422013</p>
                </div>
            </div>
*/