import React, { useEffect, useState } from 'react';
import MyNavbar from './Navbar';
import Footer from './Footer';
import TestimonialCarousel from './Testimonial';
import backgroundImage1 from './images/1stBackground.jpg'
import backgroundImage2 from './images/2ndBackground.jpg'
import About_Us from './images/About_Us_Home.jpg'
import './Home.css'
import { Carousel } from 'react-bootstrap';
import { Chrono } from "react-chrono";
import ScrollReveal from 'scrollreveal';

function Home() {

    /*const testimonials = [
        {
            name: "Anika Patel",
            text: "Metayu's products have transformed my skincare routine. I am now glowing from within!"
        },
        {
            name: "Rajiv Sharma",
            text: "Metayu Pharmatech has set the bar high in the pharmaceutical industry. I trust them."
        },
        {
            name: "Priya Menon",
            text: "I have been using Metayu's cosmeceutical products for a few months now. They work wonders."
        },
        {
            name: "Aarav Khanna",
            text: "I never knew skincare could be so effective and natural at the same time. Metayu has shown me the way."
        },
        {
            name: "Preeti Mehta",
            text: "As someone who values natural remedies, Metayu has been a game-changer for me."
        },
        {
            name: "Sahil Kapoor",
            text: "Metayu Pharmatech is the future of pharmaceuticals. I am excited to see what they come up with next."
        },
        {
            name: "Mira Shah",
            text: "Finally, a brand that truly understands the science of skincare. Metayu is my go-to now."
        },
        {
            name: "Arjun Gupta",
            text: "The fusion of modern science and ancient wisdom is beautifully reflected in Metayu's products."
        },
        {
            name: "Kavita Reddy",
            text: "I can't get enough of Metayu's range of products. Each one feels like a luxury."
        },
        {
            name: "Vikram Singh",
            text: "Being someone who is always on the go, Metayu's products have simplified my skincare routine."
        }
    ];    

    const items = [{
        title: "Cosmeceuticals Range",
        cardTitle: "Cosmeceuticals Range",
        cardDetailedText: "We are in the process of developing our proprietary range of cosmeceutical products. Leveraging the potent synergy between natural ingredients and scientific formulation techniques, our products are designed to offer unparalleled skin and hair care benefits, aligning with our commitment to holistic well-being.",
      }, {
        title: "Organic Powders",
        cardTitle: "Organic Powders",
        cardDetailedText: "Recognizing the need for safer, more natural alternatives to everyday products, our team is pioneering the development of fully organic powders aimed at replacing talc. These natural formulations promise to deliver all the benefits of traditional powders without the associated health risks, ensuring safety and efficacy.",
      },
    ]; 
    
    const [isActive, setIsActive] = useState(false); // Slideshow state

    useEffect(() => {
        // Try to play on component mount
        if (Chrono.play) { // Check if play function exists
        Chrono.play();
        setIsActive(true);
        } else {
        // Fallback: Set active state after a delay
        const timeoutId = setTimeout(() => setIsActive(true), 1000);
        return () => clearTimeout(timeoutId); // Cleanup on unmount
        }
    }, []);
    */

    return(
        <div>      
            <MyNavbar />
            <div>
                <Carousel interval={2000} pauseOnHover={false} fade={true}>
                    <Carousel.Item>
                        <img
                        src={backgroundImage1}
                        className="background-image"
                        alt="First slide"
                        />
                        <div className="center-text-home">
                            <h2 className="background-text-home">Harmonizing Tradition with Innovation for Healthier Tomorrows.</h2>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={backgroundImage2}
                        className="background-image"
                        alt="Second slide"
                        />
                        <div className="center-text-home">
                            <h2 className="background-text-home">Harmonizing Tradition with Innovation for Healthier Tomorrows.</h2>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div>
                <div className='our-story-main-div'>
                    <div style={{width: '81%', margin: 'auto'}}>
                        <h2 style={{textAlign: 'center', fontWeight: '700', fontFamily: 'Maven Pro', fontSize: '3.2em'}}>About Us</h2>
                    </div>
                    <div className='our-story-container'>
                        <div className='our-story-image-left'>
                            <img className='our-story-head-image' src={About_Us} alt="About Metayu Pharmatech" />
                        </div>
                        <div className='our-story-container1'>
                            <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '2em', borderRadius: '5px'}}>
                                <p style={{fontFamily: 'Maven Pro', fontWeight: '500'}}>Welcome to Metayu, where tradition meets transformation. We stand at the intersection of ancient wisdom and contemporary science.</p>
                                <p style={{fontFamily: 'Maven Pro', fontWeight: '500'}}>Our journey is fueled by a passion for extensive research and a commitment to sustainability, aiming to create solutions that cater to the evolving needs of healthcare and beauty. With a keen focus on the power of natural transformation, we strive to bring to life products that are not only effective but also ethically crafted, ensuring wellness for our consumers.</p>
                            </div>
                            <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '1.7em', borderRadius: '5px', marginTop: '1.1em'}}>
                                <p style={{fontFamily: 'Maven Pro', fontWeight: '500'}}>Discover the future of health and beauty with Metayu Pharmatech, where each product tells a story of innovation, quality, and the harmonious blend of tradition with modernity. Join us on this journey towards a healthier tomorrow.</p>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end', margin: '1.2em 0 0'}}><button class="button-30" role="button"><a href="/about-us" style={{textDecoration: 'none', color: '#06717E', fontWeight: '900'}}>Learn More</a></button></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{margin: '7em 0 2em'}}>
                <h2 className='home-page-title'>Research & Development</h2>
                <div className='home-rd-column'>
                    <p>Welcome to Metayu Pharmatech, where innovative spirit meets pharmaceutical and cosmeceutical brilliance! Our quirky and dynamic R&D team thrives on pushing the boundaries of beauty and health with the ancient wisdom of Ayurveda and modern scientific research.</p>
                    <p>At the core of everything we do lies a genuine desire to craft products that not only make you look and feel amazing but also care for our environment. From our ingenious formulations to our bold product design, we are here to revolutionize the way you experience health and beauty.</p>
                    <p>So, step into the world of Metayu and embark on a journey where tradition and innovation dance hand in hand, making your skincare and wellness routine a delightful, effective, and environmentally-conscious experience.</p>
                    <p style={{fontWeight: '700', fontSize: '1.7em', margin: '2em 0 -0.3em'}}>Our Current Projects</p>
                    <div className='project-div-rd'>
                        <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '2em', borderRadius: '10px'}}>
                            <div style={{display: 'flex', fontSize: '1.3em'}}>
                                <div>      
                                    <p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>Cosmeceuticals Range</p>
                                </div>
                            </div>
                            <p>We are in the process of developing our proprietary range of cosmeceutical products. Leveraging the potent synergy between natural ingredients and scientific formulation techniques, our products are designed to offer unparalleled skin and hair care benefits, aligning with our commitment to holistic well-being.</p>
                        </div>
                    </div>
                    <div className='project-div-rd'>
                        <div style={{boxShadow: '0px 20px 10em rgba(0, 0, 0, 0.1)', padding: '2em', borderRadius: '10px'}}>
                            <div style={{display: 'flex', fontSize: '1.3em'}}>
                                <div>      
                                    <p style={{padding: '0.3em 0.1em',fontWeight: '600', fontFamily: 'Maven Pro'}}>Organic Powders</p>
                                </div>
                            </div>
                            <p>Recognizing the need for safer, more natural alternatives to everyday products, our team is pioneering the development of fully organic powders aimed at replacing talc. These natural formulations promise to deliver all the benefits of traditional powders without the associated health risks, ensuring safety and efficacy.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

/*

            <div className='testimonial-div'>
                <div style={{width: '81%', margin: '3em auto 0.8em'}}>
                    <h2 style={{textAlign: 'center', fontWeight: '700', fontFamily: 'Maven Pro', fontSize: '2.6em', color: 'white'}}>What our Customers Say</h2>
                </div>
                <TestimonialCarousel testimonials={testimonials} />
            </div>


            <Chrono 
                            items={items} 
                            theme={{
                                primary: 'green',
                                secondary: 'green',
                                cardBgColor: 'white',
                                titleColor: 'green',
                                titleColorActive: 'white',
                                cardTitleColor: 'green'
                            }}  
                            slideShow={isActive}
                            slideItemDuration={4500}
                            slideShowType="reveal"
                        />
*/

export default Home