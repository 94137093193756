import React from "react";
import { Carousel, CarouselItem } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Testimonial.css";
import StarRatings from 'react-star-ratings';

const TestimonialCarousel = ({ testimonials }) => {
  return (
    <div className="testimonial-carousel">
      <Carousel interval={3000} hideControls={true}>
        {testimonials.map((testimonial, index) => (
          <CarouselItem key={index}>
            <div className="testimonial">
              <StarRatings
                rating={5}
                starRatedColor="gold"
                numberOfStars={5}
                name='rating'
                starDimension="30px"
                starSpacing="2px"
                className="custom-stars"
              />
              <div className="testimonial-content" style={{marginTop: '1.4em'}}>
                <h3>{testimonial.name}</h3>
                <p>{testimonial.text}</p>
              </div>
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimonialCarousel;
